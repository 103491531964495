import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { useIntl } from "gatsby-plugin-intl";
import Slider from "react-slick";
import ContentWrapper from "../components/ContentWrapper";

const VisionPage = (props) => {
  const intl = useIntl();
  const settings = {
    customPaging: function (i) {
      return (
        <StyledThumb>
          <StyledImg src={vision.imageSlider[i]} />
        </StyledThumb>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const vision = props.pageContext.vision;
  return (
    <Layout>
      <Helmet title={`${vision.title}| ${config.siteTitle}`} />
      <ContentWrapper
        type="aboutUs"
        isEnglish={intl.locale === "en"}
        headerPicture={vision.headerPhoto}
        headerTop={vision.title}
        content={vision.content}
        bodyPicture={vision.bodyPicture}
      />

      <SliderLayout>
        <Container>
          <StyledSlider
            {...settings}
            style={{ maxWidth: "750px", maxHeight: "1000px", zIndex: 2 }}
          >
            {vision.imageSlider.map((image, index) => {
              return (
                <StyledItem key={index}>
                  <StyledImg src={image} />
                </StyledItem>
              );
            })}
          </StyledSlider>
        </Container>
      </SliderLayout>
    </Layout>
  );
};

const StyledSlider = styled(Slider)`
  display: flex !important;
  flex-direction: column;
  max-width: 100% !important;
  /* overflow: hidden; */
  max-height: 650px;
  justify-content: center;
  min-height: 650px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    overflow: hidden;
    max-height: 300px;
    justify-content: center;
    min-height: 300px;
  }
`;

const Container = styled.div`
  width: 750px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  .slick-dots {
    position: relative;
    margin-bottom: 0px;
    margin: 0px;
    li {
      width: 100px;
      height: 75px;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 450x;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
`;

export const SliderLayout = styled.div`
  max-width: 100vw;
  display: flex;
  border: 0px;
  box-shadow: none;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 3px;
`;

const StyledItem = styled.div`
  width: 100%;
  display: flex;
  background-color: transparent;
  border-radius: 3px;
  box-shadow: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 550px;
  min-height: 550px;
  height: 550px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    max-height: 300px;
    height: 300px;
    min-height: 300px;
  }
`;

const StyledThumb = styled.a`
  width: 150px;
  height: 75px;

  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 50px;
    height: 25px;
  }
`;

export default VisionPage;
